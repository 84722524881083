<div class="container">
    <form #sf="ngForm" id="sf" novalidate>
        <div class="row">
            <div class="col-md-10 offset-md-1 shadow-lg px-md-5 py-3 border bg-white">

                <h4 class="secondheader mb-4">Enter SIM number to get Plan detail<sup class="text-danger"> *</sup> </h4>
                <p class="secondheader2">(Starts with 89 and contains 20 digits) </p>

                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" name="SImNumber" id="SImNumber" placeholder="SIM No"
                                [(ngModel)]="SIMRegistrationSecModel.SImNumber" required #SImNumber="ngModel" ngModel
                                (ngModelChange)="ValidatetheSIMCard($event)">
                            <button class="btn btn-danger" type="button" id="button-addon2"
                                (click)="ValidatetheSIMCard(SIMRegistrationSecModel.SImNumber)">Go</button>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <img class="img-fluid" src="../../assets/AppImages/sim-large.png">
                    </div>
                    <div *ngIf="!SImNumber?.valid && (SImNumber?.dirty || SImNumber?.touched)">
                        SIM number required
                    </div>
                    <div class="alert alert-danger" *ngIf="SIMCardStatus">
                        <!-- We have already received following sim card {{SIMRegistrationResponseModel.SIMCardNumberICCID}}
                        registration
                        request for the against of {{SIMRegistrationResponseModel.Indianmobilenumber}}. Please try with
                        another mobile number. -->
                        Please provide the valid sim card.!
                        <br>
                        <button type="button" class="btn btn-danger" (click)="NewSaleEntry()">
                            Back to home</button>
                    </div>
                </div>
                <div id="DisplayPlanDetails" *ngIf="DisplayPlanMessage">
                    <div class="row">
                        <h4 class="secondheader sc-color my-3 text-start">Plan Details:</h4>
                    </div>
                    <p>
                        Monthly Post paid plan of $40 + taxes, no payment or credit card details is required as of now.
                    </p>
                    <!-- <p>
                        This will be payable only after 20 days usage.
                    </p> -->
                    <ul>
                        <li>40 GB on 4G LTE</li>
                        <li>Unlimited call in Canada</li>
                        <li>Unlimited text in Canada</li>
                        <li>1000 minutes to call India</li>
                        <li>$60 setup service fee waived</li>
                    </ul>
                   
                    <div>
                        <h3>
                            <b> This is a special discounted price plan provides and offers huge saving in cost as
                                compared <br>to the usual plans offered by Fido</b>
                        </h3>
                    </div>
                    <div>
                        <h3>
                            <b>First Postpaid invoice will be generated by Fido (Rogers network) and will be payable after 20
                                days</b>

                        </h3>
                    </div>
                </div>

                <div id="DisplayPlanDetailsKoodoo" *ngIf="DisplayPlanMessageKoodoo">
                    <div class="row">
                        <h4 class="secondheader sc-color my-3 text-start">Plan Details:</h4>
                    </div>
                    <p>
                        This is an India special Prepaid plan of $40 from Koodo (Koodo runs on TELUS' award winning 5G and 4G LTE networks). This is a Free SIM and the plan bundle is complimentary for the first month.
                    </p>
                  
                    <p>
                        The plan includes:
                    </p>
                    <ul>
                        <li>30 GB data at 4G speed</li>
                        <li>Unlimited local calls and texts in Canada </li>
                        <li>1000 international minutes to call India</li>
                    </ul>
                    <p>
                        <b>
                            Next steps:
                        </b>
                    </p>
                    <ul>
                        <li>Insert the free trial Koodo Prepaid SIM into your phone once you've landed in Canada.</li>
                        <li>Sign up for Prepaid Self-Serve at <a href="https://prepaidselfserve.koodomobile.com/en/koodo/create-account"> prepaidselfserve.koodomobile.com</a></li>
                        <li>If you wish you can even change your mobile number through this</li>

                        <li><b><i>Most importantly, </i> </b>you can renew get the same free plan next month at an exclusive $40 price.</li>
                        <li>Mobile number is printed at the back of the packaging</li>
                    </ul>                   
                </div>
                <div id="mandatoryfield" *ngIf="DisplayMandatoryDiv">
                    <div class="row mb-4 align-items-center">
                        <div class="col-md-4">
                            <label for="date" class="col-form-label textbold">Arrival Date<sup
                                    class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-5">
                            <div class="row">
                                <div clas="col-md-5">
                                    <div class="input-group">
                                        <input placeholder="Date of Travel" matInput [matDatepicker]="pickerdot"
                                            id="DateofTravel" name="DateofTravel" class="form-control"
                                            [(ngModel)]="SIMRegistrationSecModel.DateofTravel" #Traveldate="ngModel"
                                            #DateofTravel="ngModel" required [min]="Startdate" [ngClass]="{
                                    'is-invalid': (DateofTravel?.invalid && IsSubmitted),
                                    'valid': !DateofTravel?.valid && (DateofTravel?.dirty || DateofTravel?.touched)
                               }">
                                        <mat-datepicker-toggle matSuffix [for]="pickerdot"></mat-datepicker-toggle>
                                        <mat-datepicker #pickerdot></mat-datepicker>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!DateofTravel?.valid && (DateofTravel?.dirty || DateofTravel?.touched)">
                                Arrival Date
                            </div>
                        </div>
                    </div>
                    <div class="row mb-4 align-items-center">
                        <div class="col-md-4">
                            <label for="tdate" class="col-form-label textbold">Arrival Date<sup
                                    class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-5">
                            <select class="form-select" aria-label="Default select example"
                                [(ngModel)]="SIMRegistrationSecModel.DateofTravelTentative" name="DateofTravelTentative"
                                id="DateofTravelTentative" #DateofTravelTentative="ngModel" [ngClass]="{
                              'is-invalid': (DateofTravelTentative?.invalid && IsSubmitted),
                              'valid': !DateofTravelTentative?.valid && (DateofTravelTentative?.dirty || DateofTravelTentative?.touched)
                         }">
                                <option selected>Confirmed</option>
                                <option>Tentative</option>
                            </select>
                            <div
                                *ngIf="!DateofTravelTentative?.valid && (DateofTravelTentative?.dirty || DateofTravelTentative?.touched)">
                                Arrival Date
                            </div>
                        </div>
                    </div>
                    <div class="row mb-4 align-items-center">
                        <div class="col-md-4">
                            <label for="email" class="col-form-label textbold">Passport front file<sup
                                    class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-4">
                            <input class="form-control" placeholder="Front" type="file" id="passportfilename"
                            name="passportfilename"  
                            [(ngModel)]="SIMRegistrationSecModel.passportfilename"
                            #passportfilename="ngModel"  required accept="image/png, image/gif, image/jpeg, .pdf, .PDF"
                            (change)="handleFileInputPassportFront($event.target.files, 'PassportFront',sf)">
                            {{ActionMessageFront}}
                        </div>
                        </div>
                        <div class="row mb-4 align-items-center" >
                        <div class="col-md-4">
                            <label for="email" class="col-form-label textbold">Passport back file<sup
                                    class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-4">
                            <input class="form-control" placeholder="Front" type="file" id="passportbackfilename"
                            name="passportbackfilename" 
                            [(ngModel)]="SIMRegistrationSecModel.passportbackfilename"
                            #passportbackfilename="ngModel"
                            required accept="image/png, image/gif, image/jpeg, .pdf, .PDF"
                            (change)="handleFileInputPassportFront($event.target.files, 'PassportBack', sf)">
                            {{ActionMessageBack}}
                        </div>
                        </div>
                        <div class="row mb-4 align-items-center">
                        
                        <div class="col-md-4">
                            <label for="email" class="col-form-label textbold">Visa stamp copy file<sup
                                    class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-4">
                            <input class="form-control" required placeholder="Front" type="file" id="visafilename"
                            name="visafilename" 
                            [(ngModel)]="SIMRegistrationSecModel.visafilename"
                             #visafilename="ngModel"
                            accept="image/png, image/gif, image/jpeg, .pdf, .PDF"
                            (change)="handleFileInputPassportFront($event.target.files, 'Visa', sf)" >
                            {{ActionMessageVisa}}
                        </div>
                        </div>
                        
                    <div class="row mb-4 align-items-center">
                        <div class="col-12 text-end">
                             <!-- {{sf.value | json }} 
                             {{sf.invalid}} -->
                            <button type="button" class="btn btn-danger" (click)="OnBackButton()"> &lt;
                                Previous</button>
                            &nbsp; &nbsp;
                            <button type="button" class="btn btn-danger" (click)="OnSubmit(SIMRegistrationSecModel)"
                                [disabled]="sf.invalid">Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>