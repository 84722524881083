import { Component, OnInit, NgModule } from '@angular/core';
import { FormsModule, Form, NgForm } from '@angular/forms/';
import { ReactiveFormsModule, Validators } from '@angular/forms/';
import { CommonModule } from '@angular/common';
import {
  SIMRegistration,
  UserResponse,
  UserRolesModel,
  FileUploadModel,
} from '../Models/simregistration';
import {
  SIMRegistrationSec,
  SIMRegistrationAddress,
} from '../Models/simregistration-sec';
import { ApplicationServiceService } from '../Services/application-service.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {
  SIMRegistrationResponse,
  UserFiles,
} from '../Models/SIMRegistrationResponse';
import { SIMValidateResponse } from '../Models/SIMValidateResponse';

@Component({
  selector: 'app-sim-card-registration-cont',
  templateUrl: './sim-card-registration-cont.component.html',
  styleUrls: ['./sim-card-registration-cont.component.scss'],
})
export class SImCardRegistrationContComponent implements OnInit {
  public RegistrationID: string;
  public SIMRegistrationSecModel: SIMRegistrationAddress;
  public SIMRegistration: SIMRegistration;
  public SIMCardStatus = false;
  public SIMRegistrationResponseModel: SIMRegistrationResponse;
  public SImNo: string;
  public IsValidSIMNumber = true;
  public DisplayPlanMessage = false;
  public Startdate: Date;
  public DisplayMandatoryDiv = false;
  public SIMValidateResponse: SIMValidateResponse;
  public DisplayPlanMessageKoodoo = false;
  public IsSubmitted = false;
  public NetworkName = 'Koodoo';
  FileUploadModel: FileUploadModel;
  ActionMessageFront: string;
  ActionMessageBack: string;
  ActionMessageVisa: string;
  SuccessMessage: boolean;

  // Passportback: File | null = null;
  // PassportFront: File | null = null;
  // VisaFileUpload: File | null = null;

  public ProvinceList: any = [
    'Alberta',
    'British Columbia',
    'Manitoba',
    'New Brunswick',
    'Newfoundland and Labrador',
    'Northwest Territories',
    'Nova Scotia',
    'Nunavut',
    'Ontario',
    'Prince Edward Island',
    'Quebec',
    'Saskatchewan',
    'Yukon',
  ];

  constructor(
    public ApplicationServiceService: ApplicationServiceService,
    public route: ActivatedRoute,
    public Router: Router
  ) {
    this.route.params.subscribe(
      (params) => (this.RegistrationID = params['RegistrationID'])
    );
    this.SIMRegistrationResponseModel = new SIMRegistrationResponse();
    this.BindDataTotheModelWhenPreviousClick(this.RegistrationID);
  }

  ngOnInit(): void {
    this.Startdate = new Date();
    this.SIMRegistrationSecModel = new SIMRegistrationAddress();
    this.SIMRegistrationResponseModel = new SIMRegistrationResponse();
    this.SIMRegistrationSecModel.SIMRegistrationID = this.RegistrationID;
    this.SIMRegistrationSecModel.DateofTravelTentative = 'Confirmed';
    this.BindDataTotheModelWhenPreviousClick(this.RegistrationID);
  }

  ValidatetheSIMCard(SIMNumber: string) {
    this.DisplayPlanMessage = false;
    this.DisplayMandatoryDiv = false;
    this.DisplayPlanMessageKoodoo = false;
    this.DisplayMandatoryDiv = false;
    if (SIMNumber.length < 15) {
      return;
    }
    this.ApplicationServiceService.SIMCardValidationByNetwork(
      SIMNumber,
      this.NetworkName
    ).subscribe((result: any) => {
      if (result.ResponseMessage === 'Valid') {
        this.SIMCardStatus = false;
        this.IsValidSIMNumber = false;
        if (result.Network === 'Fido') {
          this.DisplayPlanMessage = true;
          this.DisplayMandatoryDiv = true;
        } else if (result.Network === 'Koodoo') {
          this.DisplayPlanMessageKoodoo = true;
          this.DisplayMandatoryDiv = true;
        }
      } else if (result.ResponseMessage === 'InValid') {
        if (
          this.SIMRegistrationResponseModel.SIMCardNumberICCID !== undefined ||
          this.SIMRegistrationResponseModel.SIMCardNumberICCID !== '' ||
          this.SIMRegistrationResponseModel.SIMCardNumberICCID != null
        ) {
          if (
            this.SIMRegistrationResponseModel.SIMCardNumberICCID === SIMNumber
          ) {
            this.SIMCardStatus = true;
            this.IsValidSIMNumber = true;

            this.DisplayPlanMessageKoodoo = false;
            this.DisplayMandatoryDiv = false;
          } else {
            this.SIMCardStatus = true;
            this.IsValidSIMNumber = true;
          }
        } else {
          this.SIMCardStatus = true;
          this.IsValidSIMNumber = true;
        }
      }
    });
  }

  OnSubmit(SIMRegistrationSecModel: SIMRegistrationAddress) {
    this.IsSubmitted = true;
    if (
      SIMRegistrationSecModel.DateofTravel === undefined ||
      SIMRegistrationSecModel.DateofTravel == null
    ) {
      alert('Please provide the travel date.!');
      return;
    }

    this.route.params.subscribe(
      (params) => (this.RegistrationID = params['RegistrationID'])
    );

    this.SIMRegistrationSecModel = new SIMRegistrationAddress();
    this.SIMRegistrationSecModel.PlanID = 0;
    this.SIMRegistrationSecModel.SIMRegistrationID = this.RegistrationID;
    this.SIMRegistrationSecModel.id = 0;
    this.SIMRegistrationSecModel.SImNumber = SIMRegistrationSecModel.SImNumber;
    this.SIMRegistrationSecModel.DateofTravel =
      SIMRegistrationSecModel.DateofTravel;
    this.SIMRegistrationSecModel.DateofTravelTentative =
      SIMRegistrationSecModel.DateofTravelTentative;
    // this.SIMRegistrationSecModel.EmailID = SIMRegistrationSecModel.EmailID;
    // this.SIMRegistrationSecModel.Canadianaddress =
    //   SIMRegistrationSecModel.Canadianaddress;
    // this.SIMRegistrationSecModel.HandsetModelnumber =
    //   SIMRegistrationSecModel.HandsetModelnumber;
    // this.SIMRegistrationSecModel.PostalCode =
    //   SIMRegistrationSecModel.PostalCode;
    // this.SIMRegistrationSecModel.Province = SIMRegistrationSecModel.Province;
    // this.SIMRegistrationSecModel.UniversityName =
    //   SIMRegistrationSecModel.UniversityName;

    this.ApplicationServiceService.AddSIMRegistrationaddress(
      this.SIMRegistrationSecModel,
      this.RegistrationID
    ).subscribe((result: any) => {
      this.SIMRegistrationResponseModel = JSON.parse(result);

      this.Router.navigate(['/Thankyou/' + this.RegistrationID]);

      // this.Router.navigateByUrl('/SIMCardRegistratioFinal/' + this.SIMRegistrationResponseModel.SIMRegistrationID + '/Final');
      // this.Router.navigate(['/SIMCardRegistrationFinal/' + this.RegistrationID + '/Final']);
    });
  }

  OnBackButton() {
    this.route.params.subscribe(
      (params) => (this.RegistrationID = params['RegistrationID'])
    );
    this.Router.navigate(['/SIMCardRegistration/' + this.RegistrationID]);
    // this.Router.navigateByUrl('/SIMCardRegistration/' + this.RegistrationID);
  }

  NewSaleEntry() {
    this.route.params.subscribe(
      (params) => (this.RegistrationID = params['RegistrationID'])
    );
    this.Router.navigate(['/SIMCardRegistration']);
    // this.Router.navigateByUrl('/SIMCardRegistration/' + this.RegistrationID);
  }

  BindDataTotheModelWhenPreviousClick(RegistrationID: string) {
    this.SIMRegistrationSecModel = new SIMRegistrationAddress();
    if (RegistrationID != null && RegistrationID !== '') {
      this.ApplicationServiceService.GetSIMRegistrationDetail(
        RegistrationID
      ).subscribe((result: any) => {
        this.SIMRegistrationResponseModel = JSON.parse(result);
        this.SIMRegistrationSecModel.SIMRegistrationID =
          this.SIMRegistrationResponseModel.SIMRegistrationID;
        this.SIMRegistrationSecModel.SImNumber =
          this.SIMRegistrationResponseModel.SIMCardNumberICCID;
        this.SIMRegistrationSecModel.PlanID =
          this.SIMRegistrationResponseModel.PlanID;
        this.SIMRegistrationSecModel.id = this.SIMRegistrationResponseModel.id;
        if (
          this.SIMRegistrationSecModel.SImNumber !== undefined &&
          this.SIMRegistrationSecModel.SImNumber !== null &&
          this.SIMRegistrationSecModel.SImNumber !== ''
        ) {
          this.ValidatetheSIMCard(this.SIMRegistrationSecModel.SImNumber);
        }
        if (
          this.SIMRegistrationSecModel.DateofTravelTentative === undefined ||
          this.SIMRegistrationSecModel.DateofTravelTentative === null ||
          this.SIMRegistrationSecModel.SImNumber === ''
        ) {
          this.SIMRegistrationSecModel.DateofTravelTentative = 'Confirmed';
        }
      });
    } else {
      this.SIMRegistrationSecModel.DateofTravelTentative = 'Confirmed';
    }
  }
  handleFileInputPassportFront(files: FileList, Type: any, f: NgForm) {
    this.FileUploadModel = new FileUploadModel();
    this.FileUploadModel.fileToUpload = files.item(0);
    const fileSizeinMB = this.FileUploadModel.fileToUpload.size / (1024 * 1000);
    const size = Math.round(fileSizeinMB * 100) / 100;
    if (Type === 'Visa') {
      if (size >= 1.5) {
        f.controls['visafilename'].setValidators(Validators.required);
        this.ActionMessageVisa =
          'Maximum file size is allowed to upload only 1.5 MB.!';
        return;
      }
    } else if (Type === 'PassportFront') {
      if (size >= 1.5) {
        f.controls['passportfilename'].setValidators(Validators.required);
        this.ActionMessageFront =
          'Maximum file size is allowed to upload only 1.5 MB.!';
        return;
      }
    } else if (Type === 'PassportBack') {
      if (size >= 1.5) {
        f.controls['passportbackfilename'].setValidators(Validators.required);
        this.ActionMessageBack =
          'Maximum file size is allowed to upload only 1.5 MB.!';
        return;
      }
    }
    this.FileUploadModel.SIMRegistrationID = this.RegistrationID;
    this.FileUploadModel.id = '0';
    this.FileUploadModel.uploadtype = Type;
    this.FileUploadModel.userID = '3';
    this.ApplicationServiceService.PostFile(this.FileUploadModel).subscribe(
      (result: any) => {
        this.SuccessMessage = true;
        if (Type === 'Visa') {
          this.ActionMessageVisa = 'File has been uploaded.!';
        } else if (Type === 'PassportFront') {
          this.ActionMessageFront = 'File has been uploaded.!';
        } else if (Type === 'PassportBack') {
          this.ActionMessageBack = 'File has been uploaded.!';
        }
        setTimeout(
          function () {
            this.SuccessMessage = false;
          }.bind(this),
          5000
        );
      }
    );
  }
}
